.micro-python-editor {
    width: 100%;
    max-height: 100vh;
    height: 100%;
    min-width: 49vw;
    z-index: 0;
}

@media screen and (max-width: 768px) {
    .micro-python-editor {
        width: 100vw;
    }
}


.ace_gutter{
    z-index: 0;
}

.blocklyDropDownDiv {
    z-index:9999999999
}