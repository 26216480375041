@import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
// @import '../../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import "../../node_modules/@syncfusion/ej2-react-grids/styles/material.css";



.e-toolbar-item button .e-icons:nth-child(2) {
    display: none !important;
}

.e-toolbar-item button .e-caret {
    display: none !important;
}

// .image-pattern-style {
//     background-color: white;
//     background-size: contain;
//     background-repeat: no-repeat;
//     height: 50px;
//     width: calc((100% - 18px) / 3);
//     cursor: pointer;
//     border: 1px solid #d5d5d5;
//     background-position: center;
//     float: left;
// }

.e-dropdown-btn, .e-dropdown-btn.e-btn{
    box-shadow:0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.line-color-DDDDDD {
    background-color: #DDDDDD;
    width: 100% !important;
    margin: 0 !important;
}


.line-color-84878A {
    background-color: #84878A;
    width: 100% !important;
    margin: 0 !important;
}


.line-color-848700 {
    background-color: #848700;
    width: 100% !important;
    margin: 0 !important;
}


.line-color-0FBD8C {
    background-color: #0FBD8C;
    width: 100% !important;
    margin: 0 !important;
}


.line-color-A55B80 {
    background-color: #A55B80;
    width: 100% !important;
    margin: 0 !important;
}


.line-color-4C97FF {
    background-color: #4C97FF;
    width: 100% !important;
    margin: 0 !important;
}


.line-color-EA0075 {
    background-color: #EA0075;
    width: 100% !important;
    margin: 0 !important;
}


.line-color-205273 {
    background-color: #205273;
    width: 100% !important;
    margin: 0 !important;
}


.line-color-006400 {
    background-color: #006400;
    width: 100% !important;
    margin: 0 !important;
}


.line-color-a3d5ff {
    background-color: #a3d5ff;
    width: 100% !important;
    margin: 0 !important;
}


.line-color-d3a1a1 {
    background-color: #d3a1a1;
    width: 100% !important;
    margin: 0 !important;
}


.line-color-f25793 {
    background-color: #f25793;
    width: 100% !important;
    margin: 0 !important;
}


.line-color-0b7669 {
    background-color: #0b7669;
    width: 100% !important;
    margin: 0 !important;
}


.line-color-0092a6 {
    background-color: #0092a6;
    width: 100% !important;
    margin: 0 !important;
}


.line-color-ba6d00 {
    background-color: #ba6d00;
    width: 100% !important;
    margin: 0 !important;
}


.line-color-990000 {
    background-color: #990000;
    width: 100% !important;
    margin: 0 !important;
}


.line-color-9f2c79 {
    background-color: #9f2c79;
    width: 100% !important;
    margin: 0 !important;
}


.line-color-b76e79 {
    background-color: #b76e79;
    width: 100% !important;
    margin: 0 !important;
}


.line-color-316ccd {
    background-color: #316ccd;
    width: 100% !important;
    margin: 0 !important;
}


.line-color-400c16 {
    background-color: #400c16;
    width: 100% !important;
    margin: 0 !important;
}


.line-color-142c53 {
    background-color: #142c53;
    width: 100% !important;
    margin: 0 !important;
}


.line-color-006455 {
    background-color: #006455;
    width: 100% !important;
    margin: 0 !important;
}


.line-color-ba6d55 {
    background-color: #ba6d55;
    width: 100% !important;
    margin: 0 !important;
}


.tool-color-container {
    padding: 5px;
}

.tool-color-container ul {
    display: flex;
    padding: 10px;
}

.tool-color-container ul li {
    display: flex;
    width: 25px;
    padding: 0 !important;
}

.tool-color-container ul li:hover {
    transform: scale(1.2);
}