/* HTML: <div class="loader1"></div> */
.loader1 {
    width: 90px;
    height: 14px;
    box-shadow: 0 3px 0 #1976D2;
    position: relative;
    clip-path: inset(-40px 0 -5px)
  }
  .loader1:before {
    content: "";
    position: absolute;
    inset: auto calc(50% - 17px) 0;
    height: 50px;
    --g:no-repeat linear-gradient(#ccc 0 0);
    background: var(--g),var(--g),var(--g),var(--g);
    background-size: 16px 14px;
    animation:
      l7-1 2s infinite linear,
      l7-2 2s infinite linear;
  }
  @keyframes l7-1 {
    0%,
    100%  {background-position: 0 -50px,100% -50px}
    17.5% {background-position: 0 100%,100% -50px,0 -50px,100% -50px}
    35%   {background-position: 0 100%,100% 100% ,0 -50px,100% -50px}
    52.5% {background-position: 0 100%,100% 100% ,0 calc(100% - 16px),100% -50px}
    70%,
    98%  {background-position: 0 100%,100% 100% ,0 calc(100% - 16px),100% calc(100% - 16px)}
  }
  @keyframes l7-2 {
    0%,70% {transform:translate(0)}
    100%  {transform:translate(200%)}
  }




  /* HTML: <div class="loader"></div> */
.loader2 {
  width: 70px;
  height: 40px;
  outline: 2px solid #fff;
  background:
    radial-gradient(farthest-side,red 90%,#0000) 0 0/8px 8px no-repeat,
    radial-gradient(farthest-side,#000 90%,#0000) 0 0/8px 8px no-repeat,
    linear-gradient(#fff 0 0) 50%/10px 100% no-repeat,
    conic-gradient(from  90deg at 10px 10px,#0000 90deg,#fff 0),
    conic-gradient(from -90deg at 60px 30px,#0000 90deg,#fff 0);
  animation: l9 2s infinite;
}
@keyframes l9 {
  0%    {background-position:50%  100%,0    0   ,50%}
  12.5% {background-position:0    100%,50%  0   ,50%}
  25%   {background-position:0    0   ,50%  100%,50%}
  37.5% {background-position:50%  0   ,100% 100%,50%}
  50%   {background-position:50%  100%,100% 0   ,50%}
  62.5% {background-position:100% 100%,50%  0   ,50%}
  75%   {background-position:100% 0   ,50%  100%,50%}
  82.5% {background-position:50%  0   ,0    100%,50%}
  100%  {background-position:50%  100%,0    0   ,50%}
}